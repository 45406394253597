import { render, staticRenderFns } from "./ProductPrice.vue?vue&type=template&id=e2e6020a&"
import script from "./ProductPrice.vue?vue&type=script&lang=js&"
export * from "./ProductPrice.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {PriceField: require('/home/gitlab-runner/builds/3h9sKVRF/0/strix/frontend/apps/medicover-pl/medistore-headless-app/components/Price/PriceField.vue').default,Price: require('/home/gitlab-runner/builds/3h9sKVRF/0/strix/frontend/apps/medicover-pl/medistore-headless-app/components/Price/Price.vue').default})
